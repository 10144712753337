export class FileMetadata {
    public name: string = "";
    public active: string = "";
    public versions: VersionMetadata[] = [];
}

export class VersionMetadata {
    public version   : string        = "";
    public owner     : string        = "";
    public notes     : string        = "";
    public created   : number        = 0;
}

/*
{
  "version": "0.0.1",
  "files": [
    {
      "name": "CraftUpgrades",
      "active": "2",
      "versions": [
        {
          "version": "1",
          "created": 1688113620,
          "notes": "Initial file [CraftUpgrades] v:0.0.1",
          "owner": "root"
        },
        {
          "version": "2",
          "created": 1688113620,
          "notes": "balance update v2",
          "owner": "root"
        }
      ]
    }
  ]
}

*/