import { IBackendRoute } from "./IBackendRoute"

export namespace BackendRoutes {
    export const AUTH:IBackendRoute                                 = { controller : "Auth",                action : "Auth" }
    
    export const PROJECT_SELECT:IBackendRoute                       = { controller : "Project",             action : "Select" }
    
    export const VERSIONS_GETALL:IBackendRoute                      = { controller : "Versions",            action : "GetAllVersions" }
    export const VERSIONS_SETCURRENT:IBackendRoute                  = { controller : "Versions",            action : "SetCurrentVersions" }
    export const VERSIONS_UPDATEVERSION:IBackendRoute               = { controller : "Versions",            action : "SetVersionVisibility" }
    
    export const CONFIGS_GETCONFIGSFORVERSION:IBackendRoute         = { controller : "Configs",             action : "GetConfigsForVersions" }
    export const CONFIGS_GETFILEDATA:IBackendRoute                  = { controller : "Configs",             action : "GetFileData" }
    
    export const CONFIGS_GETFILENAMES:IBackendRoute                 = { controller : "Configs",             action : "GetFileNames" }
    export const CONFIGS_CREATEFILE:IBackendRoute                   = { controller : "Configs",             action : "CreateFile" }
    export const CONFIGS_CREATEFILEVERSION:IBackendRoute            = { controller : "Configs",             action : "CreateFileVersion" }
    export const CONFIGS_APPLYFILEVERSION:IBackendRoute             = { controller : "Configs",             action : "ApplyFileVersion" }
    
    export const DASHBOARDUSERS_GETALLUSERS:IBackendRoute           = { controller : "DashboardUsers",      action : "GetAllUsers" }
    export const DASHBOARDUSERS_UPDATEUSER:IBackendRoute            = { controller : "DashboardUsers",      action : "UpdateUserData" }
    export const DASHBOARDUSERS_CREATEUSER:IBackendRoute            = { controller : "DashboardUsers",      action : "CreateUser" }
    export const DASHBOARDUSERS_DELETEUSER:IBackendRoute            = { controller : "DashboardUsers",      action : "DeleteUser" }

    export const DASHBOARDPROJECTS_GETPROJECTINFO:IBackendRoute     = { controller : "DashboardProjects",   action : "GetProjectInfo" }
    export const DASHBOARDPROJECTS_SETPROJECTMETA:IBackendRoute     = { controller : "DashboardProjects",   action : "SetProjectMeta" }
    export const DASHBOARDPROJECTS_SETPROJECTBACKEND:IBackendRoute  = { controller : "DashboardProjects",   action : "SetProjectBackend" }

    export const FIREBASE_GETFIREBASEINFO:IBackendRoute             = { controller : "Firebase",            action : "GetInfo" }
    export const FIREBASE_UPDATEVERSIONS:IBackendRoute              = { controller : "Firebase",            action : "UpdateVersions" }
    export const FIREBASE_UPDATEFILES:IBackendRoute                 = { controller : "Firebase",            action : "UpdateFiles" }
    
    export const LOGS_GETLIST:IBackendRoute                         = { controller : "Logs",                action : "GetList" }
}