<nb-card class="window-body">

  <nb-card-header>
    Export files from v.{{gameVersion}} 
  </nb-card-header>

  <nb-card-body>

    <nb-card>
      
      <nb-card-body class="files-info-body">

        <div class="form-group-set">
          
          <div class="form-group notes">
            <label class="label">Export notes</label>
            <div style="display: flex;">
              <input type="text" 
                    placeholder="Export notes" 
                    nbInput fullWidth 
                    (change)="onUpdateNote($event)"
                    >
            </div>
          </div>

          <div class="vertical-btts-holder">
            <button nbButton 
              class="update-bttn vert-button"
              status="warning" 
              (click)="onLoadStart(true)"
              outline
              #exportButton>
              Export version
            </button>
            
            <button nbButton 
              class="update-bttn vert-button"
              status="info" 
              (click)="onLoadStart(false)"
              outline
              #zipButton>
              Get archive
            </button>
          </div>

        </div>

        
        <ng-container *ngFor="let file of gameFiles">
          <div 
            *ngIf="!downloading || isFileToExport(file.name)"
            class="export-files-holder"
            >

            <div class="export-file-name">
              <nb-checkbox
                *ngIf="!downloading"
                [checked]="isFileToExport(file.name)"
                (checkedChange)="onFileChecked(file.name, $event)"
                >
              </nb-checkbox>
              <span 
                *ngIf="downloading && !isFileLoaded(file.name) && isFileToExport(file.name)"
                class="badge badge-warning status-badge">...</span>
              <span 
                  *ngIf="downloading && isFileLoaded(file.name) && isFileToExport(file.name)"
                  class="badge badge-success status-badge">OK!</span>
                {{file.name}}
            </div>

            <div class="export-file-version-holder">
              <ng-container
                *ngFor="let Version of file.versions"
                >
                <div
                  *ngIf="!downloading || isFileVersionToExport(file.name, Version.version)"
                  class="export-file-version">

                    <nb-checkbox
                      *ngIf="!downloading"
                      [checked]="isFileVersionToExport(file.name, Version.version)"
                      (checkedChange)="onFileVersionChecked(file.name, Version.version, $event)"
                      >
                    </nb-checkbox>
                    <nb-checkbox
                      *ngIf="downloading && isFileVersionToExport(file.name, Version.version)"
                      [checked]="true"
                      disabled
                      >
                    </nb-checkbox>

                    <div class="export-version-name">
                      <span>
                        {{Version.version}}
                      </span>
                    </div>

                    <div 
                      class="export-version-active">
                      <span 
                        *ngIf="isActiveFileVersion(file.active, Version.version)"
                        class="badge badge-success">Active</span>
                    </div>

                    <div class="export-version-notes">
                      {{Version.notes}}
                    </div>

                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        
      </nb-card-body>

    </nb-card>
    <a #dwnld style="display: none"></a>

  </nb-card-body>

</nb-card>
